import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gentext-billing-redirect',
  templateUrl: './billing-redirect.component.html',
  styleUrls: ['./billing-redirect.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BillingRedirectComponent implements OnInit {
  ngOnInit() {
    const stripeToken = this.route.snapshot.queryParamMap.get('session_id');
    const type = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    );
    if (!Office.context.ui) {
      console.warn(
        'Office not initalized. This could happen if originated from Stripe',
      );
      window.location.href = 'https://account.thefinscribe.com';
    }
    Office.context.ui.messageParent(
      JSON.stringify({
        status: 'success',
        type,
        stripeToken,
      }),
    );
  }

  constructor(private route: ActivatedRoute) {}
}
