import { Inject, Injectable } from '@angular/core';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { LoggingService } from '@gentext/logging';
import { StorageService } from '@gentext/storage';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, ReplaySubject, map } from 'rxjs';
import { AUTH_CONFIGURATION, AuthConfiguration } from './auth.configuration';
import { AuthState } from './auth.state';

type Jwt = {
  exp: number;
  name: string;
  email: string;
};
type SuccessOfficeDialogArgs = {
  message: string;
  origin: string | undefined;
};

type ErrorOfficeDialogArgs = {
  error: number;
};

const ACCESS_TOKEN = 'accessToken';

type OfficeDialogArgs = SuccessOfficeDialogArgs | ErrorOfficeDialogArgs;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authState$ = new BehaviorSubject<AuthState | undefined>(undefined);
  private _error$ = new ReplaySubject<string | undefined>();

  private _msalInstance = new PublicClientApplication({
    auth: {
      clientId: this.authConfiguration.clientId,
      authority: this.authConfiguration.authority,
      redirectUri: `${window.location.origin}/auth/login`,
      knownAuthorities: [`${this.authConfiguration.tenantName}.b2clogin.com`],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
      cacheMigrationEnabled: true,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Trace,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          let severityLevel: number = SeverityLevel.Information;
          switch (level) {
            case LogLevel.Error:
              severityLevel = SeverityLevel.Error;
              break;
            case LogLevel.Warning:
              severityLevel = SeverityLevel.Warning;
              break;
            case LogLevel.Verbose:
              severityLevel = SeverityLevel.Verbose;
              break;
            case LogLevel.Trace:
              severityLevel = SeverityLevel.Verbose;
              break;
          }
          this.logging.trace({
            message: `[AuthService - MSAL]: ${message}`,
            severityLevel,
          });
        },
      },
    },
  });
  get msalInstance() {
    return this._msalInstance;
  }

  authState$ = this._authState$.asObservable();
  error$ = this._error$.asObservable();
  accessToken$ = this.authState$.pipe(map((a) => a && a.accessToken));

  private _loading$ = new BehaviorSubject(false);
  loading$ = this._loading$.asObservable();
  private _initialized = false;
  async getAccessTokenInteractive() {
    await this.refreshState();
    return this._authState$.value?.accessToken || '';
  }

  getCachedAccessToken() {
    return this._authState$.value?.accessToken || '';
  }
  async refreshState(appLanguage = ''): Promise<void> {
    this.logging.trace({
      message: '[AuthService] - refresh state',
      severityLevel: SeverityLevel.Information,
      properties: { appLanguage },
    });
    if (this._loading$.value) {
      this.logging.trace({
        message: '[AuthService] - Already refreshing state, ignoring.',
        severityLevel: SeverityLevel.Information,
      });
      return;
    }
    this.ensureInitialized();
    this._error$.next(undefined);
    try {
      this._loading$.next(true);
      const accessToken = this.getAndValidateAccessTokenFromStorage();
      if (!accessToken) {
        this.logging.trace({
          message:
            '[AuthService] - Opening sign in dialog; no access token found',
        });
        await this.signinDialog(appLanguage);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.logging.trace({
        message: `[AuthService] - Auth Error: ${JSON.stringify(e)}`,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      this._loading$.next(false);
    }
  }
  async getJwtFromStorageEmitAuthState(): Promise<void> {
    this.logging.trace({
      message:
        '[AuthService] - getting JWT from storage and emitting AuthState',
      severityLevel: SeverityLevel.Information,
    });

    const accessToken = this.getAndValidateAccessTokenFromStorage();

    this.ensureInitialized();
    this._error$.next(undefined);
    if (!accessToken) {
      this.logging.trace({
        message: `[AuthService] - getJwtFromStorageEmitAuthState no cached token`,
        severityLevel: SeverityLevel.Information,
      });
      return;
    }

    const decodedToken = jwtDecode(accessToken) as Jwt;
    const authState: AuthState = {
      accessToken,
      name: decodedToken.name,
      email: decodedToken.email,
    };
    this._authState$.next(authState);
  }

  async signOut() {
    return new Promise<void>((resolve, reject) => {
      if (!this._authState$.value) {
        resolve();
      }

      const dialogLogoutUrl = `${window.location.origin}/auth/logout`;
      let logoutDialog: Office.Dialog;

      const processLogoutMessage = () => {
        logoutDialog.close();
        this.setAccessToken(undefined);
        resolve();
      };
      const processLogoutDialogEvent = (args: OfficeDialogArgs) => {
        this.processDialogEvent(args);
      };

      Office.context.ui.displayDialogAsync(
        dialogLogoutUrl,
        { height: 20, width: 30 },
        (result) => {
          this.logging.trace({
            message: '[AuthService] - Dialog signOut result',
            properties: result,
          });
          if (result.status === Office.AsyncResultStatus.Failed) {
            this._error$.next(`${result.error.code} ${result.error.message}`);
            reject(`${result.error.code} ${result.error.message}`);
          } else {
            logoutDialog = result.value;
            logoutDialog.addEventHandler(
              Office.EventType.DialogMessageReceived,
              processLogoutMessage,
            );
            logoutDialog.addEventHandler(
              Office.EventType.DialogEventReceived,
              processLogoutDialogEvent,
            );
          }
        },
      );
    });
  }

  private setAccessToken(accessToken: string | undefined) {
    if (!accessToken) {
      this._authState$.next(undefined);
      this.storageService.setItem(ACCESS_TOKEN, undefined); // Clear token from storage
      return;
    }
    const decodedToken = jwtDecode(accessToken) as Jwt;
    const authState: AuthState = {
      accessToken,
      name: decodedToken.name,
      email: decodedToken.email,
    };
    this._authState$.next(authState);
    this.storageService.setItem(ACCESS_TOKEN, accessToken); // Store token in local storage
  }

  private getAndValidateAccessTokenFromStorage(): string | undefined {
    const accessToken = this.storageService.getItem<string>(ACCESS_TOKEN);
    let isTokenValid = true;

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken) as Jwt;
      const currentTimeInSeconds = Date.now() / 1000;
      isTokenValid = decodedToken.exp > currentTimeInSeconds;
      this.logging.trace({
        message: '[AuthService] - Access token found',
        properties: {
          isTokenValid,
        },
      });
    }
    return isTokenValid ? accessToken : undefined;
  }
  private processDialogEvent(args: OfficeDialogArgs) {
    const errorArgs = args as ErrorOfficeDialogArgs;
    if (!errorArgs) return;

    switch (errorArgs.error) {
      case 12002:
        this._error$.next(
          'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
        );
        break;
      case 12003:
        this._error$.next(
          'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
        );
        break;
      case 12006:
        // 12006 means that the user closed the dialog instead of waiting for it to close.
        // It is not known if the user completed the login or logout, so assume the user is
        // logged out and revert to the app's starting state. It does no harm for a user to
        // press the login button again even if the user is logged in.
        this.setAccessToken(undefined);
        break;
      default:
        this._error$.next('Unknown error in dialog box.');
        break;
    }
  }
  private async ensureInitialized(): Promise<void> {
    if (!this._initialized) {
      this.logging.trace({
        message: '[AuthService] - Auth MSAL Not initialized yet, intializing',
      });
      await this._msalInstance.initialize();
      this._initialized = true;
    }
  }
  constructor(
    private logging: LoggingService,
    private storageService: StorageService,
    @Inject(AUTH_CONFIGURATION) private authConfiguration: AuthConfiguration,
  ) {
    this.logging.trace({
      message: '[AuthService] - constructor auth service',
      properties: {
        authConfiguration,
      },
    });
    this.ensureInitialized();
    this._error$.subscribe(
      (e) =>
        e &&
        this.logging.trace({ message: e, severityLevel: SeverityLevel.Error }),
    );
  }

  private async signinDialog(appLanguage: string): Promise<void> {
    await this.ensureInitialized();
    let loginDialog: Office.Dialog;
    const processLoginMessage = (arg: OfficeDialogArgs) => {
      const successArg = arg as {
        message: string;
        origin: string | undefined;
      };
      if (successArg) {
        const messageFromDialog = JSON.parse(successArg.message);
        if (messageFromDialog.status === 'success') {
          // We now have a valid access token.
          loginDialog.close();
          this.setAccessToken(messageFromDialog.result);
        } else {
          // Something went wrong with authentication or the authorization of the web application.
          loginDialog.close();
          this._error$.next(messageFromDialog.result);
        }
      } else {
        // Something went wrong with authentication or the authorization of the web application.
        loginDialog.close();
        this._error$.next('The dialog returned an unexpected response.');
      }
    };

    const processLoginDialogEvent = (args: OfficeDialogArgs) => {
      this.processDialogEvent(args);
    };
    const dialogLoginUrl = `${window.location.origin}/auth/login?appLanguage=${appLanguage}`;
    Office.context.ui.displayDialogAsync(
      dialogLoginUrl,
      { height: 80, width: 30 },
      (result) => {
        this.logging.trace({
          message: '[AuthService] - Dialog signIn result',
          properties: result,
        });
        if (result.status === Office.AsyncResultStatus.Failed) {
          this._error$.next(`${result.error.code} ${result.error.message}`);
        } else {
          loginDialog = result.value;
          loginDialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            processLoginMessage,
          );
          loginDialog.addEventHandler(
            Office.EventType.DialogEventReceived,
            processLoginDialogEvent,
          );
        }
      },
    );
  }
}
