import { InjectionToken } from '@angular/core';

export interface AuthConfiguration {
  clientId: string;
  authority: string;
  apiBaseUrl: string;
  tenantName: string;
}
export const AUTH_CONFIGURATION: InjectionToken<AuthConfiguration> =
  new InjectionToken('AUTH_CONFIGURATION');
