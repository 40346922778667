<div *transloco="let t">
  <ul class="gt-chat-messages mt-4 space-y-2 pl-4 pr-2" #messagesContainer>
    <li *ngFor="let m of messages | notHidden" class="overflow-x-auto">
      <div
        [ngClass]="{
          'bg-blue-500 text-white': m.sender === 'user',
          'bg-gray-200 text-black': m.sender === 'ai',
          'bg-gray-300 text-black': m.sender === 'system',
        }"
        class="inline-block rounded-lg px-4 py-2 text-xs"
        data-testid="chat-message-item"
      >
        <div *ngIf="m.sender === 'ai'" class="flex flex-col">
          <div class="mb-2 font-bold">{{ productName }}</div>
          <div
            [innerHTML]="renderAIResponse(m.text) | safeHtml"
            class="gt-message-content mb-2 overflow-x-auto"
          ></div>

          <fluent-progress-ring
            *ngIf="!m.isCompleted"
            class="m-2 mx-auto w-[20px]"
            data-testid="chat-generation-loader"
          ></fluent-progress-ring>
          <div
            *ngIf="m.isIneligibleFeature"
            (click)="managePlanClicked.emit()"
            class="flex cursor-pointer items-center justify-center border border-gray-300 bg-gray-100 p-1 transition duration-150 ease-in-out hover:border-blue-500 hover:bg-blue-100 hover:shadow-lg"
          >
            <span class="ml-2">{{ t('Manage Plan') }}</span>
          </div>
          <div
            *ngFor="let a of m.messageActions || []"
            (click)="a.onClick()"
            class="flex cursor-pointer items-center justify-center border border-gray-300 bg-gray-100 p-1 transition duration-150 ease-in-out hover:border-blue-500 hover:bg-blue-100 hover:shadow-lg"
          >
            <span class="ml-2">{{ a.text }}</span>
          </div>
          <div
            *ngIf="m.showInsertInDocument"
            (click)="insertMessage(m)"
            class="flex cursor-pointer items-center justify-center border border-gray-300 bg-gray-100 p-1 transition duration-150 ease-in-out hover:border-blue-500 hover:bg-blue-100 hover:shadow-lg"
          >
            <img
              src="assets/CopytoDoc.png"
              [alt]="t('Copy to Word')"
              class="size-5 cursor-pointer"
              [title]="t('Copy chat to document')"
            />
            <span class="ml-2">{{ t('Insert in document') }}</span>
          </div>
        </div>
        <div *ngIf="m.sender === 'user'" class="flex flex-col">
          <div class="font-bold">{{ firstName$ | async }}</div>
          <div
            [innerHTML]="renderAIResponse(m.text) | safeHtml"
            class="gt-message-content mt-2"
          ></div>
        </div>

        <div
          *ngIf="m.sender === 'system'"
          [innerHTML]="m.text"
          class="gt-message-content"
        ></div>
      </div>
    </li>
  </ul>

  <!-- Input area -->
  <div
    *ngIf="authState$ | async as authState"
    class="fixed bottom-0 left-0 w-[calc(100%-20px)]"
    #footerBlock
  >
    <!-- calc 100% width for scrollbar-->
    <div
      *ngIf="hasExceededLimit"
      class="ml-4 mr-2 flex flex-col items-center justify-center rounded-lg bg-gray-300 px-4 pt-2 text-center text-sm text-black"
    >
      <span>
        {{ t('You have exceeded your free monthly usage limit') }}.
        {{
          t(
            'To continue using GenText, please upgrade by clicking the button below'
          )
        }}.
      </span>
      <fluent-button
        (click)="openManagePlan()"
        appearance="filled"
        class="m-2 w-full text-xs"
      >
        {{ t('Upgrade') }}
      </fluent-button>
    </div>
    <div *ngIf="showSuggestions" class="p-2 pr-0 text-xs text-gray-600">
      <p class="p-2">
        {{ t('Need help getting started? Try giving some context:') }}
      </p>
      <div
        *ngFor="let suggestion of suggestions"
        class="m-2 cursor-pointer rounded border border-gray-300 bg-gray-100 p-2 transition duration-150 ease-in-out hover:border-blue-500 hover:bg-blue-100 hover:shadow-lg"
        (click)="insertSuggestedText(suggestion.text)"
        data-testid="chat-suggestion-item"
      >
        {{ suggestion.text }}
      </div>
    </div>
    <div class="flex flex-wrap items-center bg-white py-2 pl-4 pr-2">
      <fluent-progress-ring
        *ngIf="uploading"
        class="m-2 mx-auto basis-full"
        data-testid="chat-generation-loader"
      ></fluent-progress-ring>
      <p class="mb-2 basis-full text-red-500" *ngIf="error">
        {{ error }}
      </p>

      <!-- Hidden file input -->
      <input
        type="file"
        #fileInput
        (change)="handleFileInput($event)"
        accept=".pdf,.txt,.docx,.mp3,.flac,.m4a,.mp4,.mpeg,.mpga,.oga,.ogg,.wav,.webm"
        class="hidden"
      />

      <div class="w-full rounded-lg border border-gray-300 px-2 py-1">
        <textarea
          class="mt-1 box-border w-full resize-none p-0 text-sm outline-none"
          [(ngModel)]="chatText"
          (ngModelChange)="onChatTextChange()"
          (keyup.enter)="chatText && chat()"
          name="chatText"
          [placeholder]="t('Ask me anything') + '...'"
          ngDefaultControl
          cdkTextareaAutosize
          #chatTextAutoSize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          data-testid="chat-text-area"
        ></textarea>
        <div class="flex w-full justify-end gap-2">
          <div class="group relative">
            <fa-icon
              class="cursor-pointer"
              [icon]="['far', 'paper-plane']"
              title="Send"
              (click)="chat()"
              data-testid="chat-send-button"
            ></fa-icon>
            <div
              class="pointer-events-none absolute bottom-full right-1 z-50 rounded-md bg-gray-800 p-2 text-center text-xs text-white opacity-0 shadow-md transition-opacity duration-150 ease-in-out group-hover:opacity-100"
            >
              {{ t('Send') }}
            </div>
          </div>

          <div class="group relative">
            <fa-icon
              class="cursor-pointer"
              icon="paperclip"
              (click)="handleFileInputClick()"
              data-testid="chat-upload-button"
            ></fa-icon>
            <div
              class="pointer-events-none absolute bottom-full z-50 w-[200px] rounded-md bg-gray-800 p-2 text-center text-xs text-white opacity-0 shadow-md transition-opacity duration-150 ease-in-out group-hover:opacity-100 ltr:right-1 rtl:left-1"
            >
              {{
                t(
                  'Supports pdf, txt, docx, mp3, flac, m4a, mp4, mpeg, mpga, oga, ogg, wav, webm'
                )
              }}
            </div>
          </div>
          <!-- Hidden file input -->
          <input
            type="file"
            #fileInput
            (change)="handleFileInput($event)"
            accept=".pdf,.txt,.docx,.mp3,.flac,.m4a,.mp4,.mpeg,.mpga,.oga,.ogg,.wav,.webm"
            class="hidden"
            data-testid="chat-upload-file-input"
          />
        </div>
      </div>
    </div>
  </div>
</div>
