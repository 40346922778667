import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChatUiService } from '../chat-ui.service';

@Component({
  selector: 'gentext-chat-app',
  templateUrl: './chat-app.component.html',
  styleUrl: './chat-app.component.css',
})
export class ChatAppComponent {
  clearMessages$ = this.chatService.clearMessages$;
  chatSuggestions = [
    { text: 'What is the current inflation rate according to CPI?' },
    { text: 'How has the price of Crude Oil (WTI) changed in the last week?' },
    { text: "Show me Apple's Income Statement for the last fiscal year." },
    {
      text: 'What are the latest news and sentiments on blockchain technology?',
    },
  ];
  constructor(
    private router: Router,
    private chatService: ChatUiService,
  ) {}
  managePlan(showManagePlan: boolean) {
    if (showManagePlan) {
      this.router.navigate(['/manage-plan']);
    } else {
      this.router.navigate(['/chat']);
    }
  }
}
