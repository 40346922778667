/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AUTH_CONFIGURATION,
  AuthConfiguration,
  AuthService,
} from '@gentext/auth-office';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private baseUrl = this.authConfiguration.apiBaseUrl;
  constructor(
    private authService: AuthService,
    private logging: LoggingService,
    @Inject(AUTH_CONFIGURATION) private authConfiguration: AuthConfiguration,
  ) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (httpRequest.url.includes(this.baseUrl)) {
      this.logging.trace({
        message: `[AuthInterceptor] - API request, handling. BaseURL ${this.baseUrl}, url: ${httpRequest.url}`,
      });
      return this.handleRequest(next, httpRequest);
    }
    return next.handle(httpRequest);
  }

  private handleRequest(
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
  ) {
    const accessToken = this.authService.getCachedAccessToken();

    if (!accessToken) {
      this.logging.trace({
        message: '[AuthInterceptor] - No access token',
        severityLevel: SeverityLevel.Warning,
      });
    }
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return next.handle(authReq);
  }
}
