import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AUTH_CONFIGURATION, AuthConfiguration } from './auth.configuration';
import { AuthInterceptor } from './auth.interceptor';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutcompleteComponent } from './logoutcomplete/logoutcomplete.component';
import { authRoutes } from './routes';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(authRoutes)],
  declarations: [LoginComponent, LogoutComponent, LogoutcompleteComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthOfficeModule {
  public static forRoot(
    configuration: AuthConfiguration,
  ): ModuleWithProviders<AuthOfficeModule> {
    return {
      ngModule: AuthOfficeModule,
      providers: [{ provide: AUTH_CONFIGURATION, useValue: configuration }],
    };
  }
}
