import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth.service';

const AUTH_INITIALIZED_KEY = 'auth_initialized_logout';
@Component({
  selector: 'gentext-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
  async ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as any;
    // OfficeJS is annoying with dialogs as it randomly initializes Office twice.
    // if that happens, this code is run twice, and the dialog / add-in crashes
    if (win[AUTH_INITIALIZED_KEY]) {
      return;
    }
    win[AUTH_INITIALIZED_KEY] = true;

    // logging out
    try {
      await this.authService.msalInstance.logoutRedirect();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      Office.context.ui.messageParent('');
    }
  }
  constructor(private authService: AuthService) {}
}
