import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  afterRender,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@gentext/auth-office';
import { OfficeHelperService } from '@gentext/office';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_SERVICE, ChatService } from './chat.service';
import { LICENSE_SERVICE, LicenseService } from './license.service';
import { ChatMessage, ChatReferenceResponse } from './models';
import { NotHiddenPipe } from './not-hidden-pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gentext-chat-ui-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    TranslocoModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    TextFieldModule,
    SafeHtmlPipe,
    NotHiddenPipe,
  ],

  // to be able to style the message content we need to disable view encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit {
  @Input() chatId = uuidv4();
  @Input() addOrUpdateMessage$: Observable<ChatMessage> | undefined;
  @Input() sendMessage$: Observable<string> | undefined;
  @Input() clearMessages$: Observable<void> | undefined;
  @Input() error$: Observable<string> | undefined;
  @Input() productName: string | undefined;

  @Input() suggestions = [
    { text: 'What is the current inflation rate according to CPI?' },
    { text: 'How has the price of Crude Oil (WTI) changed in the last week?' },
    { text: "Show me Apple's Income Statement for the last fiscal year." },
    {
      text: 'What are the latest news and sentiments on blockchain technology?',
    },
  ];

  @Output() managePlanClicked = new EventEmitter<void>();
  @Output() userMessageSent = new EventEmitter<string>();

  @ViewChild('chatTextAutoSize', { static: false })
  chatTextAutoSize!: CdkTextareaAutosize;

  @ViewChild('messagesContainer') private messagesContainer:
    | ElementRef
    | undefined;
  @ViewChild('footerBlock') private footerBlock: ElementRef | undefined;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  error = '';
  hasExceededLimit = false;
  chatText = '';

  showSuggestions = true;
  showTooltip = false;
  uploading = false;
  loadingChat = false;
  messages: ChatMessage[] = [];
  authState$ = this.authService.authState$;

  firstName$ = this.authState$.pipe(
    map((a) => {
      const nameParts = a?.name?.split(' ');
      return nameParts?.length ? nameParts[0] : 'User';
    }),
  );

  constructor(
    library: FaIconLibrary,
    private cdr: ChangeDetectorRef,

    private authService: AuthService,
    private officeHelperService: OfficeHelperService,
    @Inject(CHAT_SERVICE) private chatService: ChatService,
    @Inject(LICENSE_SERVICE) private licenseService: LicenseService,
  ) {
    library.addIcons(faPaperPlane, faPaperclip);

    afterRender(() => {
      if (this.messagesContainer && this.footerBlock?.nativeElement) {
        // set padding bottom of messages container to height of footer
        const height = this.footerBlock.nativeElement.clientHeight;
        this.messagesContainer.nativeElement.style.paddingBottom = `${height}px`;
      }
    });
  }

  ngOnInit() {
    this.addOrUpdateMessage$?.pipe(untilDestroyed(this)).subscribe((m) => {
      const msgIndex = this.messages.findIndex((msg) => msg.id === m.id);
      if (msgIndex >= 0) {
        this.messages.splice(msgIndex, 1);
      }
      this.messages.push(m);

      this.showSuggestions = false;
      this.scrollToBottom();
      this.cdr.detectChanges();
    });
    this.sendMessage$?.pipe(untilDestroyed(this)).subscribe((text) => {
      this.sendChatMessage(text);
    });
    this.error$?.pipe(untilDestroyed(this)).subscribe((e) => {
      this.error = e;
      this.cdr.detectChanges();
    });
    this.clearMessages$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.messages = [];
      this.cdr.detectChanges();
    });
  }

  insertSuggestedText(suggestion: string): void {
    this.chatText = suggestion;
    this.chat();
  }

  openManagePlan() {
    this.managePlanClicked.emit();
  }

  onChatTextChange(): void {
    this.cdr.detectChanges();
  }

  chat() {
    this.sendChatMessage(this.chatText);
    this.chatText = '';
    this.chatTextAutoSize.reset();
  }

  private async sendChatMessage(text: string) {
    this.showSuggestions = false;
    this.loadingChat = true;
    this.error = '';
    if (text) {
      this.userMessageSent.emit(text);
      this.messages.push({
        text: text,
        sender: 'user',
        isCompleted: true,
        showInsertInDocument: false,
      });

      this.messages.push({
        text: '',
        sender: 'ai',
        isCompleted: false,
        showInsertInDocument: false,
      });
      this.scrollToBottom();
      const references: ChatReferenceResponse[] = [];
      const aiMessage = this.messages[this.messages.length - 1];
      const subscription = this.chatService
        .sendChat(text, this.chatId)
        .subscribe({
          next: (data) => {
            switch (data.type) {
              case 'chatSystemResponse':
                if (data.response === 'INELIGIBLE_FEATURE_ACCESSED') {
                  aiMessage.isIneligibleFeature = true;
                }
                break;
              case 'chatReferenceResponse':
                references.push(data);
                break;
              case 'chatMessageResponse':
                aiMessage.text += data.message;
                break;
            }
            this.cdr.detectChanges();
            this.scrollToBottom();
          },
          complete: () => {
            aiMessage.isCompleted = true;
            aiMessage.references = references;
            aiMessage.showInsertInDocument = !aiMessage.isIneligibleFeature;
            this.loadingChat = false;
            this.cdr.detectChanges();
            subscription.unsubscribe();
          },

          error: (error) => {
            if (error.err === 'USAGE_EXCEEDED') {
              this.hasExceededLimit = true;
              this.showSuggestions = false;
            }
            aiMessage.isCompleted = true;
            aiMessage.text = error.message;
            aiMessage.isError = true;

            this.loadingChat = false;
            this.cdr.detectChanges();
          },
        });
    }
    this.licenseService.getLicense();
  }
  async insertMessage(message: ChatMessage) {
    try {
      this.error = '';
      await this.officeHelperService.insertMarkdown(
        message.text,
        undefined,
        message.references?.map((r) => r.reference),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.error = this.officeHelperService.getErrorMessage(e);
      this.cdr.detectChanges();
    }
  }

  renderAIResponse(text: string): string {
    const html = this.officeHelperService.getHtmlFromMarkdown(text);
    return html;
  }
  async handleFileInputClick() {
    if (this.chatService.handleFileInputClick) {
      if (await this.chatService.handleFileInputClick()) {
        this.fileInput.nativeElement.click();
      } else {
        // do nothing
      }
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  handleFileInput(event: Event) {
    this.uploading = true;
    this.scrollToBottom();
    this.cdr.detectChanges();
    this.error = '';
    const element = event.target as HTMLInputElement;
    const files: FileList | null = element.files;

    if (files && files.length > 0) {
      const fileToUpload = files.item(0);
      if (fileToUpload) {
        this.chatService.uploadDocument(fileToUpload, this.chatId).subscribe({
          next: () => {
            this.uploading = false;
            const successMessage = `Document "${fileToUpload.name}" successfully uploaded.`;
            this.messages.push({
              text: successMessage,
              sender: 'system',
              isCompleted: true,
              showInsertInDocument: false,
            });
            this.scrollToBottom();
            this.cdr.detectChanges();
          },
          error: (error: string) => {
            this.error = error;
            this.uploading = false;
            this.cdr.detectChanges();
          },
        });
      } else {
        this.error = 'No file selected.';
        this.uploading = false;
        this.cdr.detectChanges();
      }
    } else {
      this.error = 'File could not be found, please try again.';
      this.uploading = false;
      this.cdr.detectChanges();
    }
    element.value = ''; // Reset file input
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const scrollContainer = document.getElementById('chat-scroll-container');
      if (!scrollContainer) {
        return;
      }
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    });
  }
}
