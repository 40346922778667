import { Injectable } from '@angular/core';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setItem<T>(key: string, value: T) {
    try {
      if (value === undefined) {
        window.localStorage.removeItem(key);
        return;
      }
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      this.logging.trace({
        message: 'Storage service exception',
        properties: { e },
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  getItem<T>(key: string): T | undefined {
    //2023-03-10T09:01:24.148Z
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateReviver = function (key: string, value: any) {
      if (typeof value === 'string') {
        const a = Date.parse(value);
        if (a) {
          return new Date(a);
        }
      }
      return value;
    };
    try {
      const valueString = window.localStorage.getItem(key);
      if (valueString) {
        const value = JSON.parse(valueString, dateReviver) as T;
        return value;
      }
    } catch (e) {
      this.logging.trace({
        message: 'Storage Service exception',
        properties: { e },
        severityLevel: SeverityLevel.Error,
      });
    }
    return undefined;
  }

  constructor(private logging: LoggingService) {}
}
