import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gentext-upgrade-ad',
  templateUrl: './upgrade-ad.component.html',
  styleUrl: './upgrade-ad.component.css',
})
export class UpgradeAdComponent {
  upgrade() {
    this.router.navigate(['/manage-plan']);
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: DialogRef,
    private router: Router,
  ) {}
}
