<div
  class="relative flex max-h-screen min-h-screen flex-col bg-white p-1"
  id="app-main-card"
>
  <div class="bg-white">
    <div *ngIf="isAuthenticated" class="absolute left-0 top-0 m-2">
      <img src="assets/icon-64.png" alt="Company Logo" class="mt-1 h-7" />
    </div>
    <div class="mb-4 flex flex-col items-center">
      <h1
        *ngIf="isAuthenticated; else tmplTitle"
        class="mt-3 text-xl font-bold"
      >
        FinScribe
      </h1>
      <ng-template #tmplTitle>
        <img
          src="assets/welcome_logo.png"
          alt="FinScribe Logo"
          class="mt-16 h-20"
        />
        <h2 class="mt-16 text-lg">Please sign in</h2>
      </ng-template>
      <!-- Conditionally render the Sign in button if the user is not authenticated -->
      <ng-container *ngIf="isAuthenticated === false">
        <div class="mt-5 w-[250px]">
          <fluent-button
            class="my-1 w-full"
            (click)="signIn()"
            appearance="accent"
            [disabled]="isLoadingAuth$ | async"
            >Sign In</fluent-button
          >
          <fluent-button class="my-1 w-full" (click)="support()"
            >Help</fluent-button
          >
          <p class="pt-10 text-center text-[14px]">
            Save time and enhance your financial analysis with FinScribe.
            FinScribe taps into extensive financial market and reporting data
            from the SEC, FRED, and various US exchanges to deliver precise,
            up-to-date financial insights. <br /><br />
            Whether you're drafting reports, analyzing market trends, or
            tracking regulatory filings, FinScribe equips you with the tools to
            be more efficient and effective.
          </p>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="isAuthenticated">
      <fluent-button
        class="menu-class-name text-l absolute right-0 top-0 my-2.5 px-2 text-black md:px-4 md:py-2 md:text-3xl lg:text-4xl"
        appearance="lightweight"
        (click)="toggleMenu()"
      >
        ☰ Menu
      </fluent-button>
    </ng-container>

    <div
      *ngIf="menuVisible"
      class="absolute right-3 z-10 w-[120px] bg-white p-2 shadow-md"
    >
      <fluent-menu>
        <fluent-menu-item>
          <div *ngIf="license$ | async as license">
            <fluent-button
              [routerLink]="['/manage-plan']"
              appearance="filled"
              class="mb-2 w-full text-xs"
            >
              {{ license.planId === 'free' ? 'Upgrade' : 'Manage Plan' }}
            </fluent-button>
            <fluent-button
              appearance="filled"
              class="mb-2 w-full text-xs"
              [routerLink]="['/chat']"
            >
              Home
            </fluent-button>
          </div>
        </fluent-menu-item>
        <fluent-menu-item>
          <fluent-button
            (click)="support()"
            appearance="filled"
            class="mb-2 w-full text-xs"
          >
            Help
          </fluent-button>
          <fluent-button
            class="mb-2 w-full text-xs"
            (click)="signOut()"
            appearance="filled"
          >
            Sign out
          </fluent-button>
        </fluent-menu-item>
        <fluent-menu-item class="text-xs" *ngIf="license$ | async as license">
          License: {{ license.planId }}<br />Words Left:
          {{ license.isUnlimited ? '∞' : license.wordsLeft }}
        </fluent-menu-item>
      </fluent-menu>
    </div>
  </div>

  <gentext-alerts-banner></gentext-alerts-banner>
  <div class="ml-3 flex flex-col">
    <span *ngIf="isAuthenticated && !isManagePlanVisible">
      {{ greeting$ | async }}
    </span>
  </div>

  <div class="grow overflow-y-auto" id="chat-scroll-container">
    <router-outlet></router-outlet>
  </div>
</div>
