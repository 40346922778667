import { Pipe, PipeTransform } from '@angular/core';
import { ChatMessage } from './models';

@Pipe({
  name: 'notHidden',
  standalone: true,
  pure: false,
})
export class NotHiddenPipe implements PipeTransform {
  transform(messages: ChatMessage[]): ChatMessage[] {
    return messages.filter((message) => {
      return message.hidden === undefined || message.hidden === false;
    });
  }
}
