import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  LOGGING_CONFIGURATION,
  LoggingConfiguration,
} from './logging.configuration';
import { LoggingService } from './logging.service';

@NgModule({
  imports: [CommonModule],
  providers: [LoggingService],
})
export class LoggingModule {
  public static forRoot(
    configuration: LoggingConfiguration,
  ): ModuleWithProviders<LoggingModule> {
    return {
      ngModule: LoggingModule,
      providers: [{ provide: LOGGING_CONFIGURATION, useValue: configuration }],
    };
  }
  constructor(
    loggingService: LoggingService,
    @Optional() @SkipSelf() module: LoggingModule,
  ) {
    if (module !== null) {
      throw 'Logging module already imported. Please only import at the root application.';
    }
    loggingService.configure();
  }
}
