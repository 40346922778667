import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AUTH_CONFIGURATION, AuthConfiguration } from '../auth.configuration';
import { AuthService } from '../auth.service';

const AUTH_INITIALIZED_KEY = 'auth_initialized';
@Component({
  selector: 'gentext-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  selectedLanguage = '';
  locale = '';

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // Use ActivatedRoute to subscribe to the queryParams Observable
      this.selectedLanguage = params['appLanguage'] || ''; // Assign selectedLanguage to appLanguage param from the URL
    });
    this.handleAuth();
  }

  private handleAuth() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as any;
    // OfficeJS is annoying with dialogs as it randomly initializes Office twice.
    // if that happens, this code is run twice, and the dialog / add-in crashes
    if (win[AUTH_INITIALIZED_KEY]) {
      return;
    }
    win[AUTH_INITIALIZED_KEY] = true;
    this.logging.trace({
      message: 'Calling handleRedirectPromise',
      severityLevel: SeverityLevel.Information,
    });
    // handleRedirectPromise should be invoked on every page load
    this.authService.msalInstance
      .handleRedirectPromise()
      .then((response) => {
        // If response is non-null, it means page is returning from AAD with a successful response
        this.logging.trace({
          message: 'Response received from handleRedirectPromise',
          properties: { response },
        });
        if (response) {
          Office.context.ui.messageParent(
            JSON.stringify({
              status: 'success',
              result: response.idToken,
            }),
          );
        } else {
          // Otherwise, invoke login
          switch (this.selectedLanguage) {
            case 'spanish':
              this.locale = 'es';
              break;
            case 'mandarin':
              this.locale = 'zh-Hans';
              break;
            case 'german':
              this.locale = 'de';
              break;
            default:
              this.locale =
                this.selectedLanguage.length >= 2
                  ? this.selectedLanguage.substring(0, 2)
                  : 'en';
              break;
          }

          this.authService.msalInstance.loginRedirect({
            scopes: [
              'openid',
              'profile',
              `https://${this.authConfiguration.tenantName}.onmicrosoft.com/read_license/license`,
            ],
            prompt: 'select_account',
            extraQueryParameters: {
              ui_locales: this.locale,
            },
          });
        }
      })
      .catch((error) => {
        this.logging.trace({
          message: '[LoginComponent] - Login component error',
          properties: {
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            message: error.message,
          },
          severityLevel: SeverityLevel.Warning,
        });
        const message = `${error.errorMessage}`;
        if (message.includes('AADB2C90118')) {
          this.authService.msalInstance.loginRedirect({
            authority: `https://${this.authConfiguration.tenantName}.b2clogin.com/${this.authConfiguration.tenantName}.onmicrosoft.com/B2C_1A_PasswordReset`,
            scopes: [
              'openid',
              'profile',
              `https://${this.authConfiguration.tenantName}.onmicrosoft.com/read_license/license`,
            ],
            extraQueryParameters: {
              ui_locales: this.locale,
            },
          });
          return;
        }
        if (error.errorCode === undefined || error.errorMessage === undefined) {
          this.logging.trace({
            message:
              '[LoginComponent] - Unexpected error from handleRedirectPromise',
            properties: {
              error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
              message: error.message,
            },
            severityLevel: SeverityLevel.Critical,
          });
          const errorData =
            error.message && error.message !== ''
              ? error.message
              : `An unexpected error occurred. Please close and re-open the add-in and try again.`;
          Office.context.ui.messageParent(
            JSON.stringify({ status: 'failure', result: errorData }),
          );
          return;
        }
        const errorData = `Code: ${error.errorCode}, Message: ${error.errorMessage}`;
        this.logging.trace({
          message: '[LoginComponent] - MSAL Error',
          properties: {
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            message: error.message,
            errorData,
          },
          severityLevel: SeverityLevel.Warning,
        });
        Office.context.ui.messageParent(
          JSON.stringify({ status: 'failure', result: errorData }),
        );
      });
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private logging: LoggingService,
    @Inject(AUTH_CONFIGURATION) private authConfiguration: AuthConfiguration,
  ) {}
}
