import { Route } from '@angular/router';
import { authRoutes } from '@gentext/auth-office';
import { CHECKOUT_SESSION, MANAGE_PLAN } from './billing-dialog.service';
import { BillingRedirectComponent } from './billing-redirect/billing-redirect.component';
import { ChatAppComponent } from './chat-app/chat-app.component';
import { ManagePlanComponent } from './manage-plan/manage-plan.component';
import { RedirectComponent } from './redirect/redirect.component';

export const routes: Route[] = [
  {
    path: 'manage-plan',
    component: ManagePlanComponent,
  },
  {
    path: 'chat',
    component: ChatAppComponent,
    data: { reuseRoute: true },
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: `billingredirect/${CHECKOUT_SESSION}`,
    component: BillingRedirectComponent,
  },
  {
    path: `billingredirect/${MANAGE_PLAN}`,
    component: BillingRedirectComponent,
  },
  {
    path: 'auth',
    title: 'Authentication',
    children: authRoutes,
  },
];
