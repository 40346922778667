<div *transloco="let t">
  <div
    *ngIf="showFailedPaymentBanner$ | async"
    (click)="clickFailedPaymentBanner()"
    class="mb-4 flex w-full cursor-pointer flex-row items-center justify-center bg-red-700 px-4 py-2 text-center text-white"
  >
    <fa-icon [icon]="['fas', 'triangle-exclamation']"></fa-icon>
    <span class="pl-2">
      {{ t('A recent payment has failed') }}.
      {{ t('Update your payment method to continue your plan') }}.
    </span>
  </div>
</div>
