<div class="rounded-lg bg-white p-8 text-center shadow-lg">
  <h2 class="mb-4 text-2xl font-bold">Upgrade to a Paid Plan</h2>
  <p class="mb-4 text-gray-600">
    Sorry, this feature is only available with a paid plan. Upgrade now to
    unlock it!
  </p>
  <button
    class="rounded border-0 bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600"
    (click)="upgrade()"
  >
    Upgrade Now
  </button>
</div>
