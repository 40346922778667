import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { map, tap } from 'rxjs';
import { BillingDialogService } from '../billing-dialog.service';
import { LicenseService } from '../license.service';

@Component({
  selector: 'gentext-alerts-banner',
  standalone: true,
  imports: [CommonModule, TranslocoModule, FontAwesomeModule],
  templateUrl: './alerts-banner.component.html',
  styleUrl: './alerts-banner.component.css',
})
export class AlertsBannerComponent {
  showFailedPaymentBanner$ = this.licenseService.license$.pipe(
    tap((license) => console.log('license', license)),
    map((license) => license?.hasPendingInvoice),
  );
  constructor(
    private billingDialogService: BillingDialogService,
    private licenseService: LicenseService,
    library: FaIconLibrary,
  ) {
    library.addIcons(faTriangleExclamation);
  }
  clickFailedPaymentBanner() {
    this.billingDialogService.showManagePlanDialog();
  }
}
